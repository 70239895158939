@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($core-typography-config);

$theme: map-merge(
	mat.define-light-theme($primary-palette, $accent-palette, $danger-palette),
	(
		foreground: (
			base: $color-dark-primary-text,
			text: $color-dark-primary-text,
			divider: $color-dark-primary-text,
		),
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

:root {
	color: $color-dark-primary-text;
	--bp-color-primary: #{$color-primary};
	--bp-color-primary-light: #{$color-primary-light};
	--bp-color-primary-dark: #{$color-primary-dark};
}
