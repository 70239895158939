$font-family: 'Museo', 'Roboto', sans-serif;

$color-mist             : #f9f9ff;
$color-blue-zodiac      : #0f213c;
$color-biscay           : #173055;
$color-bittersweet      : #ff6666;
$color-spring-green     : #33ff99;
$color-grey-mystic      : #d8dfea;
$color-mystic           : #e9edf3;
$color-slate-gray       : #7a8595;
$color-dark-primary-text: $color-blue-zodiac;
$color-border           : #bfc9d6;
$color-comet            : #585c6d;
$color-link-water       : #eff3fa;
$color-lynch            : #77869f;
$color-hawkes-blue      : #eef3fe;
$color-trout            : #4e5664;
$color-titan-white      : #f7f7ff;
$color-faded-dark-primary-text: fade-out($color-dark-primary-text, 0.35);

$root-container-max-width   : 1200px;
$root-container-padding-y   : 15px;
$root-container-padding-x-sm: 20px;
$root-container-padding-x-md: 30px;
$root-container-padding-x   : 30px;

$border-radius: 10px;

$breakpoint-phone     : 375px; //iphone 6/7/8
$breakpoint-phone-lg  : 500px;
$breakpoint-widescreen: $root-container-max-width + $root-container-padding-x * 2;
