a,
button {
	&.mat-button-base {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: auto;
		border-color: $color-border;

		&.promo-xs-button {
			@include heightAndLineHeight(26px);
			border-radius: 13px;
			padding: 0 13px;
			font-size: 12px;
			font-weight: $font-weight-bold;
		}

		&.promo-sm-button {
			@include heightAndLineHeight(39px);
			min-width: 147px;
		}

		&.promo-md-button {
			@include heightAndLineHeight(45px);
			min-width: 155px;
		}

		&.promo-lg-button {
			@include heightAndLineHeight(60px);
			min-width: 270px;
			border-radius: 64px;
		}
	}

	&.mat-stroked-button {
		&.active {
			color: $color-primary;
			border-color: currentColor;
			pointer-events: none;
		}
	}
}

.promo-flat-button.mat-flat-button,
.promo-stroked-button.mat-stroked-button {
	@include heightAndLineHeight(52px);
	padding: 0.175em 1.75em;
	border-radius: 50px;
	min-width: 200px;
}

.promo-flat-button.mat-flat-button:not(.mat-button-disabled) {
	box-shadow: 0 10px 20px rgba(0, 125, 234, 0.15);

	&:hover {
		background-color: darken($color-primary, 5%);
	}
}

.promo-stroked-button.mat-stroked-button {
	border-color: white;
}

bp-pending-btn {
	position: relative;

	button {
		width: 100%;
	}

	.mat-progress-spinner {
		position: absolute;
		right: unset !important;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
		padding: 5px 0;

		svg {
			position: static;
		}

		&,
		svg {
			@include size(100% !important);
		}
	}

	.mat-button-wrapper {
		transition: opacity $transition-props;
	}

	&.pending {
		.mat-button-wrapper {
			opacity: 0;
		}
	}
}
