@use '@angular/material' as mat;

.link,
.link-with-arrow-before,
.link-with-arrow-after {
	@include mat.typography-level($core-typography-config, button);
	line-height: 1.5;
}

.link {
	@include promoLink('after');
}

.link-with-arrow-before {
	@include promoLink('after');

	&:before {
		@include arrow-right();
		transform: rotate(180deg);
		margin-right: 0.3125em;
	}
}

.link-with-arrow-after {
	@include promoLink('before');

	&:after {
		@include arrow-right();
		margin-left: 0.3125em;
	}
}
