form.promo-form {
	mat-form-field.mat-form-field-appearance-outline {
		width: 100%;

		mat-select .mat-select-value,
		input,
		.mat-form-field-prefix,
		.mat-form-field-suffix {
			position: unset;
			top: unset !important;
		}

		.mat-form-field-wrapper {
			margin: 0.25em 0 0;
		}

		&:not(.mat-focused):not(.mat-form-field-invalid) {
			.mat-form-field-label {
				color: $color-slate-gray;
			}
		}

		.mat-form-field-outline > div {
			background: white;
		}

		.mat-form-field-outline-start {
			min-width: 1.7333em !important;
			border-radius: 1.7333em 0 0 1.7333em !important;
		}

		.mat-form-field-outline-end {
			border-radius: 0 1.7333em 1.7333em 0 !important;
		}

		.mat-form-field-flex {
			filter: drop-shadow($control-shadow);
			padding-left: 2em !important;
			padding-right: 2em !important;
		}

		.mat-form-field-infix,
		.mat-form-field-suffix {
			height: 3.184em !important; // 44px height of the input with 15px font size
		}

		.mat-form-field-infix {
			padding: 0.3em 0 !important;
			width: 120px;

			.mat-form-field-label-wrapper {
				top: -1em;
			}
		}

		// Main label centering
		.mat-form-field-label {
			top: 1.6em !important;
		}

		// Floating label compensation
		&.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
		&.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
			transform: translateY(-1.29375em) scale(0.75) !important;
		}

		.mat-select-arrow-wrapper {
			transform: none !important;
		}

		.mat-form-field-subscript-wrapper {
			margin-top: 0.15em;
			padding: 0 2.7em;
		}

		&.input-with-suffix-button-lg {
			font-size: 16px;

			.mat-form-field-infix,
			.mat-form-field-suffix {
				height: 3.25em !important; // 48px height of the input with 16px font size

				@include media('>=tablet') {
					height: 3.5em !important; // 52px height of the input with 16px font size
				}
			}

			.mat-form-field-infix {
				padding: 0.4em 0 !important;

				@include media('>=tablet') {
					padding: 0.5em 0 !important;
				}
			}

			.mat-form-field-flex {
				align-items: center;
				padding-right: 0 !important;
			}

			// Floating label compensation
			&.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
			&.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
				transform: translateY(-1.39375em) scale(0.75) !important;

				@include media('>=tablet') {
					transform: translateY(-1.49375em) scale(0.75) !important;
				}
			}

			// Main label centering
			.mat-form-field-label {
				top: 1.64375em !important;

				@include media('>=tablet') {
					top: 1.74375em !important;
				}
			}

			button,
			.mat-button-base {
				$margin-offset: 4px; // floating label corrective
				height: calc(100% - #{$margin-offset}); // to follow the form field height
				min-width: unset;
				margin-top: $margin-offset;
				width: 88px;
				box-shadow: none;
			}
		}
	}

	.global-error {
		color: $color-danger;
		margin-bottom: 1.5rem;
		font-weight: $font-weight-bold;
	}
}
