:root {
	overflow-x: hidden;
}

.route-animated {
	display: block; // for the route container page to be smoothly animated between route transitions
}

.elevated-card {
	transition: box-shadow $transition-props;
	@include elevation(10, $color-blue-zodiac, 0.33);

	&:hover {
		@include elevation(15, $color-blue-zodiac, 0.5);
	}
}
