@use '@angular/material' as mat;

:root {
	@include mat.typography-hierarchy($phone-base-typography-config);

	@include media('>=tablet') {
		@include mat.typography-hierarchy($tablet-base-typography-config);
	}

	@include media('>=laptop') {
		@include mat.typography-hierarchy($laptop-base-typography-config);
	}

	// .mat-typography prefix is required to overwrite mat-typography non-standard styles
	// https://github.com/angular/components/blob/master/src/material/core/typography/_typography.scss#L123
	.mat-h5,
	.mat-typography h5 {
		font-size: 18px;
		line-height: 30px;
		font-weight: 700;

		@include media('>=tablet') {
			font-size: 20px;
			line-height: 32px;
		}
	}
}

b,
strong {
	font-weight: $font-weight-extra-bold;
}
